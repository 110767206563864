@import 'src/styles/base/variables';

footer {
  .center {
    background: linear-gradient(90deg, $color-primary-dark 0.01%, $color-primary 99.99%);
    * {
      color: white;
    }
    a {
      display: block;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }

    .privacy-block {
      border-top: 1px solid #f8f5f9;
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &__divider {
        width: 1px;
        height: 26px;
        background: white;
        margin: 0 25px;
      }
    }

    &__content {
      padding-top: 37px;
      padding-bottom: 57px;
      display: grid;
      grid-template-columns: 0.7fr 1fr 1.2fr 1fr;
      grid-gap: 10px;

      &__item {
        span {
          display: block;
        }

        &__title {
          margin-bottom: 20px;
          font-size: 18px;

          &--follow {
            text-align: center;
            margin-bottom: 45px;
          }
        }

        &--logo {
          display: flex;
          align-items: center;
        }
        &--follow {
          border-left: 1px solid #f8f5f9;
          .social-links {
            display: flex;
            justify-content: center;
            align-items: center;
            grid-gap: 10px;
            gap: 10px;
          }
        }
        &__logo {
          width: 139px;
        }

        .address-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          margin-right: 40px;
        }
      }
    }
  }
  .bottom {
    background: white;
    &__content {
      padding: 13px 0 26px 0;
      display: grid;
      grid-gap: 28px;
      grid-template-columns: auto auto;
      align-items: center;
      span {
        font-size: 12px;
      }
    }
  }

  @include breakpoint-md {
    span,
    a {
      font-size: 14px;
    }
    .center {
      &__content {
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
        &__item {
          &__title {
            font-size: 14px;
            margin-bottom: 10px;
          }
          &--follow {
            display: flex;
            flex-direction: column;
            .social-links {
              margin: auto;
            }
          }
        }
      }
    }
  }
  @include breakpoint-sm {
    span,
    a {
      font-size: 12px;
    }
    .center {
      .privacy-block {
        padding: 10px 0;
        padding-bottom: 18px;
      }
      &__content {
        grid-template-columns: 1fr;
        row-gap: 25px;
        padding-bottom: 20px;

        &__item {
          &__logo {
            width: 80px;
            margin-bottom: 15px;
          }
          &__title {
            margin-bottom: 10px;
          }
          &--logo {
            border-bottom: 1px solid #f8f5f9;
          }
          &--follow {
            border: none;
            align-items: flex-start;
            .social-links {
              margin: 0;
            }
          }
        }
      }
      .privacy-block {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        justify-content: flex-start;
        &__divider {
          margin: 10px 13px;
          &--4 {
            display: none;
          }
        }
      }
    }
    .bottom {
      &__content {
        grid-template-columns: 1fr;
        place-items: center;
      }
    }
  }
}
footer.intermediary-footer {
  .center {
    background: $color-verdigris;
  }
}
.visually-hidden {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}
