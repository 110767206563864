@import 'src/styles/base/variables';

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $color-text;
}

h1 {
  font-size: 56px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-lg {
    font-size: 40px;
  }
  @include breakpoint-md {
    font-size: 32px;
  }
  @include breakpoint-sm {
    font-size: 24px;
  }
}

h2 {
  font-size: 40px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-lg {
    font-size: 32px;
  }
  @include breakpoint-md {
    font-size: 24px;
  }
  @include breakpoint-sm {
    font-size: 20px;
  }
}
h3 {
  font-size: 30px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-lg {
    font-size: 26px;
  }
  @include breakpoint-md {
    font-size: 22px;
  }
  @include breakpoint-sm {
    font-size: 20px;
  }
}
h4 {
  font-size: 26px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-lg {
    font-size: 24px;
  }
  @include breakpoint-md {
    font-size: 20px;
  }
  @include breakpoint-sm {
    font-size: 18px;
  }
}
h5 {
  font-size: 20px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-lg {
    font-size: 18px;
  }
  @include breakpoint-md {
    font-size: 16px;
  }
  @include breakpoint-sm {
    font-size: 12px;
  }
}
h6 {
  font-size: 18px;
  font-weight: 600;
  color: $color-primary-dark;
  font-family: 'Diodrum Cyrillic';
  line-height: normal;

  @include breakpoint-md {
    font-size: 16px;
  }
  @include breakpoint-sm {
    font-size: 14px;
  }
}

p {
  font-size: 16px;
  color: $color-text;
  line-height: normal;

  @include breakpoint-md {
    font-size: 14px;
  }
  @include breakpoint-sm {
    font-size: 12px;
  }
}

li {
  font-size: 16px;
  color: $color-text;

  @include breakpoint-md {
    font-size: 14px !important;
  }
  @include breakpoint-sm {
    font-size: 12px !important;
  }
}
