@font-face {
  font-family: 'Diodrum Cyrillic';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Medium'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Diodrum Cyrillic';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Bold'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Diodrum';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Extralight'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Diodrum Cyrillic';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Semibold'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Diodrum Cyrillic';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Regular'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Diodrum Cyrillic';
  src: url('/src/assets/fonts/diodrum/DiodrumCyrillic-Light.eot');
  src:
    local('/src/assets/fonts/diodrum/Diodrum Cyrillic Light'),
    local('/src/assets/fonts/diodrum/DiodrumCyrillic-Light'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Light.eot?#iefix') format('embedded-opentype'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Light.woff2') format('woff2'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Light.woff') format('woff'),
    url('/src/assets/fonts/diodrum/DiodrumCyrillic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
