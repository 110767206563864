* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

input,
button,
textarea {
  font-family: inherit;
  font-size: 100%;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:hover,
a:visited {
  text-decoration: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}
