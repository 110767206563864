@import 'variables';

.btn {
  background-color: $color-primary;
  color: white;
  padding: 10px 30px;
  border: 1.6px solid white;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-primary;
  white-space: nowrap;

  &--header {
    border-top-right-radius: 0;
    padding: 10px 0px;
    width: 243px;
  }

  &--verdigris {
    background-color: $color-verdigris;
    border: 1px solid $color-verdigris;
  }
  &--verdigris-secondary {
    background-color: white !important;
    border: 1px solid $color-verdigris !important;
    color: $color-verdigris !important;
  }
  &--large {
    padding: 12px 47px;
  }

  &:hover {
    background-color: $color-primary-dark;
    border: 1px solid $color-primary-dark;
  }

  &--secondary {
    background-color: $color-lp-1;
    color: $color-primary;

    &:hover {
      color: white;
    }
  }

  &--full-width {
    width: 100%;
  }

  &:disabled {
    background-color: gray;
    border: 1px solid rgb(95, 95, 95);
    cursor: default;
  }

  @include breakpoint-lg {
    &--header {
      width: 180px;
    }
  }
  @include breakpoint-md {
    padding: 8px 20px;
    font-size: 14px;
    &--header {
      width: unset;
    }
    &--large {
      padding: 9px 20px;
    }
  }
  // @include breakpoint-sm {
  //   font-size: 12px;
  // }
}

.input {
  display: block;
  width: 100%;
  padding: 13px 10px 14px 22px;
  background-color: transparent;
  line-height: 1.2;
  color: white;
  transition: background-color 0.3s;
  &::placeholder {
    font-size: 16px;
    line-height: 1.2;
    color: rgba(white, 0.5);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: rgba($color-lp-2, 0.1);
  }

  @include breakpoint-lg {
    font-size: 12px;
    padding: 12px 10px 11px 15px;
    &::placeholder {
      font-size: 12px;
    }
    &::placeholder {
      font-size: 12px;
    }
  }
  @include breakpoint-sm {
    padding: 10px 5px 10px 15px;
  }
}

.radio-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  &:checked + label {
    &::after {
      opacity: 1;
    }
  }
}

.radio-label {
  cursor: pointer;
  display: inline-block;
  padding-left: 36px;
  position: relative;
  color: $color-text;
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50px;
    border: 1px solid $color-text;
    background-color: white;
    position: absolute;
    left: 0;
    top: 1px;
  }
  &::after {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50px;
    background-color: $color-primary;
    position: absolute;
    left: 3px;
    top: 4px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  @include breakpoint-md {
    padding-left: 28px;
    &::before {
      width: 20px;
      height: 20px;
    }
    &::after {
      width: 14px;
      height: 14px;
    }
  }
  @include breakpoint-sm {
    padding-left: 23px;
    &::before {
      width: 15px;
      height: 15px;
      top: 2px;
    }
    &::after {
      width: 11px;
      height: 11px;
      left: 2px;
    }
  }
}

.select {
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 30px;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 13px 40px 14px 22px;
  background: url('/src/assets/svg/icon-arrow-down-white.svg') right 20px center / 12px 7px no-repeat;
  font-size: 16px;
  line-height: 1.2;
  color: #a58bab;
  transition: background-color 0.3s;
  option {
    font-weight: 400;
    color: $color-text;
  }
  &::-ms-expand {
    display: none;
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: rgba($color-lp-2, 0.1);
  }

  @include breakpoint-lg {
    padding: 11px 40px 12px 15px;
    font-size: 12px;
    background-size: 11px 6px;
    background-position: right 15px center;
  }
  @include breakpoint-sm {
    padding: 10px 35px 10px 15px;
  }
}

input[type='radio'] {
  min-height: 24px;
  min-width: 24px;
  accent-color: $color-primary;
  cursor: pointer;
}

label {
  cursor: pointer;
  @include breakpoint-md {
    font-size: 14px;
  }
  @include breakpoint-sm {
    font-size: 12px;
  }
}

input[type='checkbox'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border: 1px solid $color-text;
  border-radius: 3px;
  display: grid;
  place-content: center;

  @include breakpoint-sm {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }
}

input[type='checkbox']::before {
  content: '';
  width: 14px;
  height: 14px;
  transform: scale(0);
  // transition: 120ms transform ease-in-out;
  background-color: $color-primary;

  @include breakpoint-sm {
    width: 11px;
    height: 11px;
  }
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select {
  cursor: pointer;
  display: block;
  min-width: 192px;
  border-radius: 30px;
  border: 1px solid $color-primary;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 13px 32px 13px 20px;
  background: url('/src/assets/svg/icon-arrow-down-violet.svg') right 16px center / 11px 6px no-repeat;
  font-size: 16px;
  line-height: 1.2;
  color: $color-primary;
  transition: background-color 0.3s;
  option {
    font-weight: 400;
  }
  option[value=''] {
    display: none;
  }
  &::-ms-expand {
    display: none;
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: $color-lp-2;
  }

  @include breakpoint-md {
    min-width: 122px;
    padding: 8px 25px 8px 15px;
    font-size: 14px;
  }
  @include breakpoint-sm {
    min-width: 100px;
    padding: 7px 20px 7px 12px;
    font-size: 12px;
  }
}
