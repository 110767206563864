@import 'src/styles/base/variables';

* {
  font-family: 'Roboto', sans-serif;
}

main {
  min-height: calc(100vh - 170px);
}

.no-scroll {
  overflow: hidden;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flex1 {
  flex: 1;
}

.absolute {
  position: absolute;
}

.mr-10 {
  margin-right: 10px;
}

.text-center {
  text-align: center;
}

.spacer-responsive-50 {
  height: 50px;

  &--debug {
    border: 1px solid red;
  }

  @include breakpoint-md {
    height: 30px;
  }
  @include breakpoint-sm {
    height: 20px;
  }
}
.spacer-responsive-30 {
  height: 30px;

  &--debug {
    border: 1px solid red;
  }

  @include breakpoint-md {
    height: 20px;
  }
  @include breakpoint-sm {
    height: 15px;
  }
}

.standard-list {
  margin-left: 16px;

  li {
    font-size: 16px;
    color: $color-text;
    font-family: 'Diodrum Cyrillic';

    &::marker {
      font-size: 14px;
    }
  }
}

.container {
  // border: 1px solid gray;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  &__content {
    margin: 0 60px;
  }

  @include breakpoint-md {
    &__content {
      margin: 0 20px;
    }
  }
  @include breakpoint-sm {
    &__content {
      &--hero {
        margin-right: 0;
        .hero__left {
          margin-right: 20px;
        }
      }
    }
  }
}

.color-white {
  color: white;
}
.color-primary {
  color: $color-primary;
}
.color-primary-dark {
  color: $color-primary-dark;
}

.link {
  color: $color-primary;
  text-decoration: underline;
  text-decoration-color: $color-primary;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color-primary;
  }
}
.link-text {
  color: $color-primary;
  text-decoration-color: $color-primary;
  cursor: pointer;

  &--white {
    color: white;
    text-decoration-color: white;
  }
}

.no-underline {
  text-decoration: none;
}
.hidden {
  display: none !important;
}

.decor-divider {
  display: block;
  width: 181px;
  height: 7px;
  background: linear-gradient(
    80deg,
    $color-primary 0%,
    $color-primary 27%,
    $color-primary-dark 27.01%,
    $color-primary-dark 100%
  );
  border-radius: 10px 0 10px 0;
  transform: skewX(-10deg);

  &--small {
    width: 90px;
  }

  @include breakpoint-md {
    width: 100px;
    height: 5px;
  }
  @include breakpoint-sm {
    width: 53px;
    height: 3px;
  }
}
.decor-verdigris {
  background: linear-gradient(
    80deg,
    $color-primary 0%,
    $color-primary 27%,
    $color-verdigris 27.01%,
    $color-verdigris 100%
  );
}

.banner {
  padding: 10px 0;
  text-align: center;
  background: $color-lp-2;
  font-size: 12px;
  font-weight: 500;
  p {
    font-size: 24px !important;
    color: white !important;
  }
  &--dark {
    background: $color-primary-dark;
  }
  &--intermediary {
    background-color: $color-verdigris-dark;
    padding: 13px 0px;
    span {
      font-size: 16px;
      font-weight: 400;
    }
    @include breakpoint-md {
      span {
        font-size: 14px;
      }
    }
    @include breakpoint-sm {
      p {
        font-size: 14px !important;
      }
    }
  }
  @include breakpoint-md {
    p {
      font-size: 16px !important;
    }
  }
  @include breakpoint-sm {
    p {
      font-size: 12px !important;
    }
  }
}

.styled-scroll {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-primary;
    position: absolute;
  }
}
