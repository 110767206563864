@import 'src/styles/base/variables';

.app-header {
  position: relative;
  z-index: 1000;
  background-color: white;

  a {
    text-decoration: none;
  }
  ul {
    li {
      list-style-type: none;
    }
  }

  .skip-to-content {
    padding: 10px 20px;
    background-color: black;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    font-size: 14px;
    font-weight: 600;
    transform: translateY(-45px);
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-decoration-color: white;
      transform: translateY(0px);
    }
    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible,
    &:hover {
      transform: translateY(0px);
    }
  }

  &__content {
    padding: 20px 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__ctas {
      align-items: center;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 10px;

      &__link {
        margin-right: 30px;
        color: $color-primary-dark;
        font-size: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
      }

      // border: 1px solid red;
    }
  }

  &__underline {
    height: 12px;
    background-color: $color-primary;
  }

  &__bar {
    margin: auto;
    max-width: 1050px;
    margin-bottom: 21px;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    position: relative;
    z-index: 101;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      li {
        list-style-type: none;
      }
    }

    &__link {
      padding: 0 20px;
      color: $color-primary;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-color: $color-primary;
      }
    }
  }

  #burger-menu {
    display: none;
  }

  @include breakpoint-md {
    &__content {
      .logo {
        width: 100px;
      }
      &__ctas {
        &__link {
          margin-right: 10px;
          img {
            display: none;
          }
        }
      }
    }

    &__bar {
      margin-bottom: 10px;
      &__link {
        font-size: 14px;
        padding: 0 8px;
      }
    }
    &__underline {
      height: 8px;
    }
  }

  @include breakpoint-sm {
    &__content {
      .logo {
        width: 68px;
      }
      &__ctas {
        display: none;
      }
    }
    &__bar {
      display: none;
    }
    &__underline {
      height: 5px;
    }
    #burger-menu {
      display: block;
    }
  }
}

// Intermediary styles
.intermediary-app-header {
  .app-header {
    &__underline {
      background-color: $color-verdigris;
    }
    &__bar {
      max-width: 880px;
      &__link {
        color: $color-verdigris-dark;
      }
    }
    &__content {
      .logo {
        max-height: 85px;
        object-fit: contain;
      }
    }
  }
  .menu-desktop__content__right .link-item {
    color: $color-verdigris-dark !important;
  }
}

// Burger Menu Styles
#burger-menu {
  cursor: pointer;
  height: 28px;
  width: 28px;
  overflow: visible;
  position: relative;
  z-index: 2;

  span {
    background: $color-primary;
    display: block;
    height: 3px;
    width: 28px;
    position: absolute;
    top: 13px;
    right: 0;
    transition: 0.3s ease-in-out;
    border-radius: 2px;

    &:before,
    &:after {
      content: '';
      background: inherit;
      display: block;
      height: 3px;
      width: 28px;
      position: absolute;
      transition: inherit;
      border-radius: inherit;
    }

    &:before {
      top: -10px;
      left: 0;
    }

    &:after {
      top: 10px;
      left: 0;
    }
  }

  &.close {
    span {
      transform: rotate(-45deg);

      &:before {
        transform: rotate(90deg);
        top: 0;
      }

      &:after {
        transform: rotate(90deg);
        top: 0;
        opacity: 0;
        width: 0;
      }
    }
  }
}

// Menu Styles
#menu {
  z-index: 100;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  padding-top: 40px;
  background: white;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &.overlay {
    visibility: visible;
    opacity: 1;
    padding-top: 74px;
  }

  .root {
    display: flex;
    flex-direction: column;
    grid-gap: 1px;
    gap: 1px;
    padding-top: 7px;
    &__item {
      background-color: $color-lp-1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 20px;
      user-select: none;
      -webkit-tap-highlight-color: transparent;

      span {
        color: $color-primary;
        font-size: 18px;
        display: block;
      }
      .mobile-menu {
        display: flex;
        width: 100%;
        &-item {
          flex-basis: 50%;
          display: flex;
          align-items: center;
          a {
            width: 100%;
          }
          &:nth-of-type(2) {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .links-wrap {
    padding-top: 24px;
    background-color: $color-lp-1;
    flex: 1;
    height: 100%;

    .link-item {
      display: block;
      font-size: 12px;
      cursor: pointer;
      color: $color-primary;
      margin-bottom: 8px;
    }
    .temp-disabled {
      pointer-events: none;
      * {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }

    h4 {
      margin-bottom: 20px;
      font-size: 14px !important;
    }
  }

  .menu__back {
    display: flex;
    align-items: center;
    padding: 13px 20px;
    gap: 9px;
    grid-gap: 9px;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 14px;
    user-select: none;

    &__b-label {
      color: $color-primary;
      font-size: 18px;
    }

    &__title {
      margin: 0;
      margin-left: 20px;
      color: $color-primary-dark;
      font-family: 'Diodrum Cyrillic';
      font-size: 18px;
      font-weight: 600;
      display: block;
      // line-height: 10px;
    }
    &__divider {
      margin-left: 20px;
      width: 60px;
      margin-top: 4px;
      margin-bottom: 14px;
    }
  }

  .savings {
    flex: 1;
  }
  .mortgages {
    flex: 1;
  }

  #menu__bottom {
    margin-top: auto;
    margin-bottom: 22px;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      grid-gap: 10px;
      margin-bottom: 10px;

      &__ctas {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, auto);

        &__link {
          padding: 0 10px;
          color: $color-primary-dark;
          font-size: 14px;
          display: flex;
          align-items: center;
        }

        // border: 1px solid red;
      }
    }
  }
}

.menu-desktop {
  width: 100%;
  position: absolute;
  left: 0;
  transform: translateY(-65px);

  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    z-index: 100;
    padding-top: 77px;
    min-height: 420px;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.1));

    &__left {
      background-color: $color-lp-1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      h2 {
        right: 0;
        flex: 1;
        max-width: 400px;
        text-align: center;
      }
    }
    &__right {
      background-color: white;
      padding: 23px 64px 5px 64px;
      // border: 1px solid red;

      h4 {
        margin-bottom: 26px;
      }

      &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        margin-bottom: 30px;
        .item {
          // border: 1px solid red;
          // border: 1px solid blue;
          ul {
            &:focus {
              outline: 4px solid red;
            }

            li {
              &:focus {
                outline: 4px solid blue;
              }
            }
          }
        }
      }

      .temp-disabled {
        pointer-events: none;
        * {
          color: rgba(0, 0, 0, 0.5) !important;
        }
        cursor: default;
      }

      .link-item {
        display: block;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        color: $color-primary;
        margin-bottom: 8px;

        &:hover {
          text-decoration: underline;
          text-decoration-color: $color-primary;
        }
      }
    }
  }

  @include breakpoint-md {
    transform: translateY(-50px);
    &__content {
      padding-top: 58px;
      min-height: 360px;
      grid-template-columns: 220px auto;
      min-height: 400px;
      &__right {
        padding: 20px 30px 10px 30px;
        &__grid {
          grid-template-columns: 180px auto;
        }
        .link-item {
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
    }
  }
}

.intermediary-mobile-menu {
  padding-top: 90px !important;
  .root__item span {
    color: $color-verdigris-dark !important;
  }
  .root {
    padding-top: 17px !important;
    background-color: $color-lp-1;
  }
}
